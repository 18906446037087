import * as React from 'react';
import { StaticFooterNavigation } from '../navigation/footernav';
import './footer.css';

export const Footer: React.FC<{}> = () => {
  let newDate = new Date();
  let year = newDate.getFullYear();
  return (
    <div className="page-centered">
      <footer className="relative">
        <section className="inner flex px-8 py-8">
          <div className="child">
            <h4 className="mt-8">Quick Links</h4>
            <StaticFooterNavigation />
          </div>
          <div className="child">
            <h4 className="mt-8">Related Links</h4>
            <a href="https://www.harteresearchinstitute.org" rel="noopener noreferrer" target="_blank" title="Harte Research Institute">
              Harte Research Institute
            </a>
            <a href="https://www.tamucc.edu" rel="noopener noreferrer" target="_blank" title="Texas A&amp;M University - Corpus Christi">Texas A&amp;M University - Corpus Christi</a>
            <a href="http://www.gulf360.org" rel="noopener noreferrer" target="_blank" title="Gulf360">Gulf360</a>
            <a href="https://gomaportal.tamucc.edu/gecoview" rel="noopener noreferrer" target="_blank" title="GecoView">GecoView</a>
            <a href="http://www.gulftree.org" rel="noopener noreferrer" target="_blank" title="GulfTree">GulfTree</a>
            <a href="https://www.bluevalue.blog" rel="noopener noreferrer" target="_blank" title="BlueValue Blog">BlueValue Blog</a>
          </div>
          <div className="child">
            <h4 className="mt-8">Contact us</h4>
            <div className="location flex"><span className="icon h-5 mr-2"></span>
              <p className="text-base">
                <a href="https://www.harteresearchinstitute.org/research/socio-economics" rel="noopener noreferrer" target="_blank" title="Harte Research Institute">
                  Socio-Economics Group
                </a>
                Harte Research Institute<br />
                Texas A&amp;M University–Corpus Christi<br />
                6300 Ocean Drive, Unit 5869<br />
                Corpus Christi, Texas 78412</p></div>
            <div className="mail flex">
              <span className="icon w-5 h-5 mr-2"></span>
              <a href="mailto:seg-hri@tamucc.edu" target="_blank" rel="noreferrer noopener">seg-hri@tamucc.edu</a>
            </div>
          </div>
          <div className="child">
            <h4 className="mt-8">Connect with us</h4>
            <div className="flex">
              <a href="https://www.facebook.com/HRISocioEconomicsGroup" target="_blank" rel="noreferrer noopener" className="facebook-link icon w-5 h-5 mr-2" title="HRISocioEconomicsGroup on Facebook"></a>
              <a href="https://twitter.com/HRI_SEG" target="_blank" rel="noreferrer noopener" className="twitter-link icon w-5 h-5 mr-3" title="HRI_SEG on Twitter"></a>
              <a href="https://www.instagram.com/hri_socioeconomics/" target="_blank" rel="noreferrer noopener" className="instagram-link icon w-5 h-5" title="hri_socioeconomics on instagram"></a>
            </div>
            <span className="relative hri-logo w-64 h-16"><a href="https://www.harteresearchinstitute.org/" target="_blank" rel="noreferrer noopener" className="link-block inset-0 absolute"></a></span>
            <p className="text-base">BlueValue is a project of the Socio-Economics Group at the Harte Research Institute at Texas A&amp;M University-Corpus Christi. Copyright &copy; {year}</p>
          </div>
        </section>
      </footer>
    </div>
  );
};
